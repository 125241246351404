<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="公司名称:">
          <el-select v-model="queryForm.tenantID" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="账单汇总编号：">
          <el-input v-model="queryForm.summaryNo" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="发薪时间:">
        <el-date-picker value-format="yyyy-MM-dd"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
          </el-date-picker>
          </el-form-item>
          <el-form-item class="search">
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
    </div>
    <!-- <div class="sys-info">
      <div class="info-a">系统发薪总人数: <span class="info-jc">{{PayrollInfo.total_Salary_Number}}</span>人</div>
      <div class="info-b">系统发薪总金额: <span class="info-jc">{{PayrollInfo.total_Salary}}</span>元</div>
    </div> -->
    <div style="text-align: left;margin-bottom: 20px;">
        <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom()">上传钱袋宝账单</el-button>
      </div>
    <div>
      <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        show-summary
        border
        v-loading="loading"
      >
        <el-table-column prop="sss" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>
              {{
              scope.$index + 1 + (queryForm.pageNo - 1) * queryForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作人" :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="totalTaxString" label="账单汇总编号" :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="reqsta" label="业务请求状态" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in itemList" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.reqsta">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rtnflg" label="业务请求结果" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in itemLists" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.rtnflg">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="errdsp" label="错误描述" :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="preStatus" label="发薪失败条数" :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="totalNumber" label="总条数" :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="totalAmountString" label="总金额（元）" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true" align="center" />
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button @click="noResult(scope.row)" :loading="scope.row.loading" type="text" size="small">{{scope.row.loading?  '正在获取支付结果' : '获取支付结果'}}</el-button> 
            <el-button @click="noDetails(scope.row)" v-if="scope.row.rtnflg == 'S' " type="text" size="small">查看明细数据</el-button> 
            <el-button @click="findstatement(scope.row)" v-if="scope.row.accountChannel != 3 && scope.row.accountChannel != 0" type="text" size="small">获取流水</el-button> 
            <el-button @click="printstatement(scope.row)" v-if="scope.row.accountChannel != 3 && scope.row.accountChannel != 0" type="text" size="small">下载流水PDF</el-button> 
            <el-button @click="openUploadFroms(scope.row)" type="text" size="small" v-if="scope.row.accountChannel == 0 " >上传钱袋宝流水</el-button>
            <el-button @click="downPDF(scope.row)" type="text" size="small" v-if="scope.row.accountChannel == 3 " >下载钱袋宝流水</el-button>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页栏 -->
      <el-pagination
        id="page"
        background
        align="right"
        @size-change="handleSizeChange"
        @current-change="salarysizeChange"
        :page-sizes="[10,20,30,50]"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <div>
      <!-- 外层 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
          <!-- 内层 -->
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            <el-form-item label="发薪月份:">
              <el-date-picker
                v-model="updateForm.createTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="账单渠道：">
              <el-select v-model="updateForm.billChannel" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户主体：">
              <el-select v-model="queryForm.tenantId" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
            </el-form-item>
            <el-form-item :label="title"  prop="isFile" >
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="upload"
                :on-preview="handlePreview"
                :on-error="handleError"
                :on-remove="handleRemoves"
                :on-success="handlesuccess"
                :file-list="fileList"
                multiple
                accept=".xls,.xlsx"
                :data="uploadJson"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog :title="titles" :visible.sync="dialogFormVisibles" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
          <!-- 内层 -->
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            
            <el-form-item :label="titles"  prop="isFile" >
              <el-upload
                class="upload-demo"
                ref="uploads"
                :action="uploads"
                :on-preview="handlePreviews"
                :on-error="handleErrors"
                :on-remove="handleRemoves"
                :on-success="handlesuccesss"
                :file-list="fileList"
                :limit=1
                :data="uploadJsons"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">请上传钱袋宝PDF流水。</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibles = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoadings" @click="submitUploadFroms">确 定</el-button>
          </div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      upload:process.env.VUE_APP_BASE_URL+'/salarySummarizing/importQdbData',
      uploads:process.env.VUE_APP_BASE_URL+'/common/uploadFileOSS',
      title:'上传钱袋宝账单',
      titles:'上传钱袋宝流水',
      queryForm:{
        tenantID:'',
        time:[],
        pageNo:1,
        pageSize:10,
        summaryNo:''
      },
      options: [{
          value: '0',
          label: '钱袋宝账单'
        }, {
          value: '1',
          label: '发薪账单'
        }],
        
      fileList: [],
      fileLists: [],
      fullscreenLoading: false,
      fullscreenLoadings: false,
      uploadJson:{
        json: {},
        userId: '',
        flag:1,
        sessionId:'',
        tenantId:'',
        createTime:'',
        type: process.env.VUE_APP_UPLOAD_TYPE,
        queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
      },
      urlList:[],
      uploadJsons:{
        json: {},
        userId: '',
        flag:1,
        rescordId:'',
        sessionId:'',
        tenantId:'',
        createTime:'',
        type: process.env.VUE_APP_UPLOAD_TYPE,
        queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
      },
      dialogFormVisible: false,
      dialogFormVisibles: false,
      tenants:'',
      tableData:[],
      total:0,
      itemList:[],
      itemLists:[],
      diction: {
          code: "summaryStatus",
      },
      dictions: {
          code: "salaryRequestStatus",
      },
      updateForm: {
        groupIds: [],
        groupId: this.queryFormGetGroupIds,
        groupName: '',
        sessionId: '',
        type: 0,
        creditLimitApprovalId: '',
        isFile: null,
        tenantId:'',
        billChannel:'',
        createTime:'',
      },
      PayrollInfo:'',
      loading: false,
      salaryInfo:{
        buscod:'',
        yurref:'',
        bgndat:'',
        enddat:'',
        salarySummarizingId:'',
        salarySummarizingRecordsId:'',
        zhtenantID:'',
        createTime:'',
      },
      statement:{
        id:'',
        begdat:'',
        enddat:'',
        begidx:'',
        ptyref:'',
      },
      
      diction: {
				code: "salarySummaryInfoStatus",
			},
      // 表格对象
      updateFormRules: {
        isFile:[
            { required: true, message: '请选择文件！', trigger: 'change' }
        ],
        ten:[
            { required: true, message: '请选择客户主体！', trigger: 'change' }
        ]
      },
    };
  },
  async created() {
    this.loadTable();
    
  },
  // 挂载查询方法
  mounted() {
    this.findDictionariesItemList(this.diction);
    this.findDictionariesItemLists(this.dictions);
  },
  methods: {
    // 查询
    async loadTable() {
      this.loading = true;
      await this.api.salaryApi.payrollLog(this.queryForm).then(res => {
          res = res.data;
          if (res.code == 200) {
            this.tableData = res.data.records.map(item =>{
              this.loading = false;
              return item;
            })
            this.total = res.data.total;
          }
        });
        // this.listPayrollInfos();
        this.listTenantInfos()
    },
    //查询系统总数据
    async listPayrollInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.listPayrollInfo(this.queryForm).then(res => {
        console.log("-----",res)
          this.PayrollInfo = res.data.data;
          console.log("-----",this.PayrollInfo)
        });
    },
    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants(this.queryForm).then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },
    //获取支付结果
    async noResult(row) {
      row.loading = true;
      var time1 = row.createTime;
      var time2 = row.createTime;
      time1 = time1.split(' ')[0]
      var reg1 = new RegExp("-","g"); // 加'g'，删除字符串里所有的"a"
      time1 = time1.replace(reg1,"");
      // console.log(time1);
      this.salaryInfo.buscod = row.buscod;
      this.salaryInfo.yurref = row.yurref;
      this.salaryInfo.bgndat = time1
      this.salaryInfo.enddat = time1
      this.salaryInfo.createTime = time2
      this.salaryInfo.salarySummarizingId = row.salarySummarizingId;
      this.salaryInfo.salarySummarizingRecordsId = row.id
      this.salaryInfo.zhtenantID = row.tenantId
      console.log("-----row",this.salaryInfo)
      await this.api.salaryApi.finSalarySummaryInfo(this.salaryInfo).then(res => {
        console.log("-----",res)
        console.log("-----",res.data.code)
        if(res.data.code==200){
          this.loadTable();
          row.loading = false;
          this.$message.success("获取成功")
          
        }
      });
    },

    //获取对账单ID
    async findstatement(row) {
      var time = row.createTime;
      time = time.split(' ')[0]
      this.statement.id = row.id;
      this.statement.begdat = time;
      this.statement.enddat = time;
      this.statement.begidx = '0';
      this.statement.ptyref = row.yurref;
      console.log("-----row",this.statement)
      await this.api.salaryApi.statementOfAccount(this.statement).then(res => {
        console.log("-----",res)
        console.log("-----",res.data.code)
        if(res.data.code==200){
          this.$message.success("获取成功,请操作打印")
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
    
    //打印对账单
    async printstatement(row) {
      this.statement.id = row.id;
      console.log("-----row",this.statement)
      await this.api.salaryApi.printStatement(this.statement).then(res => {
        console.log("-----",res)
        console.log("-----",res.data.code)
        if(res.data.code==200){
          setTimeout(() =>{
            window.open(res.data.data.fileurl)
          },2000)
          this.$message.success(res.data.msg)
        }else if(res.data.code==404){
          this.$message.error("请先获取对账单流水！");
        }else if(res.data.code==304){
          this.$message.error("正在处理对账单流水，请稍后再下载！");
        }
      });
    },

    downPDF(row){
      window.open(row.statementPdf)
    },

    
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.itemList = response.data.data;
    },
    // 点击上传钱袋宝账单
    openUploadFrom(){
      this.dialogFormVisible = true;
    },
    // 点击上传钱袋宝账单PDF
    openUploadFroms(row){
      this.dialogFormVisibles = true;
      this.uploadJsons.rescordId = row.id;
    },
    submitUploadFrom(){
      console.log('开始上传');
      this.uploadJson.sessionId = this.$getCookie("sessionId");
      this.uploadJson.tenantId = this.queryForm.tenantId;
      this.uploadJson.createTime = this.updateForm.createTime;
      this.$refs.upload.submit();
      console.log('上传成功！');
    },
    submitUploadFroms(){
      console.log('开始上传');
      this.uploadJsons.sessionId = this.$getCookie("sessionId");
      this.$refs.uploads.submit();
      console.log('上传成功！');
    },
    //清空弹窗表单
    afteraddOrUpdateDialogClose(){
        // this.resetForm('updateForm');
        this.$refs.upload.$data.uploadFiles = [];
    },
    // 删除一选择的上传文件函数
        handleRemove(file, fileList) {
          console.log('handleRemove!');
          this.updateForm.isFile = null;
          console.log(file, fileList);
        },
        handleRemoves(file, fileLists) {
          console.log('handleRemoves!');
          this.updateForm.isFile = null;
          console.log(file, fileLists);
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) {
          console.log('handlePreview!');
          console.log(file);
        },
        handlePreviews(file) {
          console.log('handlePreviews!');
          console.log(file);
        },
        // 上传失败的钩子
        handleError(err, file, fileList){
          this.$message.error('上传失败！');
        },
        handleErrors(err, file, fileLists){
          this.$message.error('上传失败！');
        },
        // 上传成功的钩子
        handlesuccess(response, file, fileList){
          this.updateForm.groupId = [];
          this.fileList = []
          this.dialogFormVisible = false;
          this.fullscreenLoading = true;
          if(response.code == 200){
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message({
            message: '上传成功！',
            type: 'success'
          });
            this.loadTable();
          }, 2000);
          // console.log('handlesuccess',response);
        }else{
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message({
            message: '上传失败,请按规范上传文件!',
            type: 'error'
          });
            this.loadTable();
          }, 2000);
        }
        },
        handlesuccesss(response, file, fileLists){
          this.updateForm.groupId = [];
          this.fileLists = []
          this.dialogFormVisibles = false;
          this.fullscreenLoadings = true;
          if(response.code == 200){
          setTimeout(() => {
            this.fullscreenLoadings = false;
            this.$message({
            message: '上传成功！',
            type: 'success'
          });
            this.loadTable();
          }, 2000);
          // console.log('handlesuccess',response);
        }else{
          setTimeout(() => {
            this.fullscreenLoadings = false;
            this.$message({
            message: '上传失败,请按规范上传文件!',
            type: 'error'
          });
            this.loadTable();
          }, 2000);
        }
        },
    // 分页改变时触发
    salarysizeChange(page) {
      this.queryForm.pageNo = page;
      this.loadTable();
    },
    handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.loadTable();
    },
    // 重置
    onReset() {
      this.queryForm.time=[],
      this.queryForm.tenantID=null;
    },
    // 查看函数
    noDetails(row){
        this.$router.push({path: '/payrollLogDetails',query: {salarySummarizingId: row.salarySummarizingId,salarySummarizingRecordsId: row.id,createTime:row.createTime,activeName:'first',summNO: row.totalTaxString}})
    },
    // 导出
    derive() {},
    async findDictionariesItemLists(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.itemLists = response.data.data;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    afi(fmt){
      // Date.prototype.format = function(fmt) { 
          var o = { 
              "M+" : this.getMonth()+1,                 //月份 
              "d+" : this.getDate(),                    //日 
              "h+" : this.getHours(),                   //小时 
              "m+" : this.getMinutes(),                 //分 
              "s+" : this.getSeconds(),                 //秒 
              "q+" : Math.floor((this.getMonth()+3)/3), //季度 
              "S"  : this.getMilliseconds()             //毫秒 
          }; 
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt; 
      // }       
    },
    //除法函数
    accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
  }
};
</script>

<style scoped>
#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.sys-info{
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  background: #00FFFF;
  border-radius: 10px;
}
.info-a{
  margin: 0 100px 0 10px;
}
.info-b{

}
.info-jc{
  color: red;
  font-weight: 900;
  font-size: 24px;
}
.search{
  margin-left: 50px;
}
</style>
